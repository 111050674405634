import * as React from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import { ExpandMore, ExpandLess, DeleteOutline } from "@mui/icons-material";
import axios from "axios";

function CoachNotes(props) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [boxHeight, setBoxHeight] = React.useState("200px");

  const handleExpandClick = () => {
    setIsExpanded((prev) => !prev);
    setBoxHeight((prevHeight) => (prevHeight === "200px" ? "500px" : "200px"));
  };

  // Track both the original and current note values
  const [currentNotes, setCurrentNotes] = React.useState("");
  const [userNotes, setUserNotes] = React.useState([]);

  React.useEffect(async () => {
    await getUserNotes();
  }, [props.srURL]);

  // Handle typing in the text field
  const handleTextChange = (event) => {
    setCurrentNotes(event.target.value);
  };

  // Handle uploading/saving the notes
  const handleSaveUpdate = async () => {
    await addNote();
    setCurrentNotes("");
    getUserNotes();
  };

  // Handle canceling the changes
  const handleCancel = () => {
    setCurrentNotes("");
  };

  const addNote = async () => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_FLASK_URL}/addnote?sr_url=` +
          props.srURL +
          "&userid=" +
          props.username +
          "&comment=" +
          encodeURIComponent(currentNotes)
      );
    } catch (error) {
      console.error("Error posting the note:", error);
    }
    //console.log("posting the note.");
  };

  const removeNote = async (noteId) => {
    if (
      window.confirm(
        "Are you sure you want to delete this note? This action is not reversible."
      )
    ) {
      try {
        // Call the API to remove the note
        await axios.post(
          `${process.env.REACT_APP_FLASK_URL}/removenote?note_id=` + noteId
        );

        setUserNotes((prevNotes) =>
          prevNotes.filter((note) => note.noteId !== noteId)
        );
      } catch (error) {
        console.error("Error removing the note:", error);
      }
    } else {
      console.log("Delete action canceled.");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const options = { year: "numeric", month: "short", day: "2-digit" };

    return date.toLocaleDateString("en-US", options);
  };

  const getUserNotes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_FLASK_URL}/notes?sr_url=` + props.srURL
      );
      if (
        response == null ||
        response.data == null ||
        response.data.length === 0
      ) {
        setUserNotes([]);
      } else {
        const formattedData = response.data
          .slice()
          .reverse()
          .map((item) => ({
            name: item.userid,
            date: formatDate(item.date_added),
            noteId: item.note_id,
            description: item.comment,
          }));
        setUserNotes(formattedData);
      }
      // console.log(response.data);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request aborted");
      } else {
        console.error("Error:", error);
      }
    }
  };

  const SingleUserNote = ({ name, date, description, onDelete }) => {
    return (
      <>
        <Box
          sx={{
            marginBottom: "15px",
            marginTop: "15px",
            marginRight: "10px",
            marginLeft: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{ fontWeight: "bold", fontFamily: "Libre Franklin" }}
              >
                {name}
              </span>
              <span
                style={{
                  marginLeft: "10px",
                  opacity: "0.5",
                  fontSize: "12px",
                  // fontStyle: "italic",
                }}
              >
                {date}
              </span>
            </Box>
            {props.username === name ? (
              <IconButton
                edge="end"
                onClick={onDelete}
                sx={{
                  marginRight: "3px",
                }}
              >
                <DeleteOutline sx={{ fontSize: "18px" }} />
              </IconButton>
            ) : (
              <></>
            )}
          </Box>
          <p style={{ marginTop: "5px", whiteSpace: "pre-wrap" }}>
            {description}
          </p>
        </Box>
        <Divider variant="middle" />
      </>
    );
  };

  return (
    <Card sx={{ marginBottom: "30px" }}>
      <Box
        sx={{
          width: "100%",
          marginTop: "10px",
          paddingLeft: props.isMobile ? "10px" : "0px",
          paddingRight: props.isMobile ? "10px" : "0px",
        }}
      >
        <TextField
          hiddenLabel
          placeholder="Notes will be visible to all users"
          variant="outlined"
          multiline
          maxRows={5}
          fullWidth
          spellCheck
          autoComplete
          sx={{
            mb: 1,
          }}
          value={currentNotes}
          onChange={handleTextChange}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <Button
            sx={{
              color: "#BF5700",
              borderColor: "#BF5700",
              height: "30px",
              margin: "0px",
              padding: "10px",
            }}
            variant="outlined"
            onClick={handleCancel}
            disabled={currentNotes === ""}
          >
            Cancel
          </Button>
          <Button
            className="save-notes-button"
            sx={{
              background: "#BF5700",
              color: "white",
              height: "30px",
              margin: "0px",
              padding: "10px",
              "&:hover": {
                background: "#d1762a", // Change this to your desired hover color
              },
            }}
            onClick={handleSaveUpdate}
            disabled={currentNotes === ""}
          >
            Add Note
          </Button>
        </Box>
      </Box>
      {userNotes.length > 0 ? (
        <Box
          sx={{
            marginBottom: { xs: "15px", md: "10px" },
            paddingTop: "20px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              overflow: "auto !important",
              maxHeight: boxHeight,
              marginLeft: "7px",
              paddingBottom: "10px",
              marginTop: "-20px",
            }}
          >
            {userNotes.map((block, index) => (
              <SingleUserNote
                key={index}
                name={block.name}
                date={block.date}
                description={block.description}
                onDelete={() => removeNote(block.noteId)}
              />
            ))}
          </Box>
          <IconButton
            sx={{
              position: "absolute",
              bottom: "-25px",
              right: "10px",
            }}
            onClick={handleExpandClick}
          >
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
      ) : (
        <></>
      )}
    </Card>
  );
}

export default CoachNotes;
