import * as React from "react";
import { Card, Box, Typography, Skeleton } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";
import ProgressArc from "./ProgressArc";
import { styled } from "@mui/material/styles";

const StyledTable = styled(Table)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    padding: "10px",
  },
  [theme.breakpoints.up("md")]: {
    padding: "10px",
  },
  [theme.breakpoints.up("lg")]: {
    padding: "15px",
  },
}));

const StyledTableCellHeader = styled(TableCell)(({ theme }) => ({
  cursor: "default",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.75em",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "1em",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "0.9em",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1em",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "1.4rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.4rem",
  },
}));

function RoleSummary(props) {
  const [showHiddenStats, setShowHiddenStats] = React.useState(false);

  let hasHiddenStats = props.stat5Name != undefined ? true : false;

  const toggleStats = (event) => {
    if (showHiddenStats) {
      setShowHiddenStats(false);
    } else {
      setShowHiddenStats(true);
    }
  };

  let button;
  if (showHiddenStats) {
    button = (
      <Typography
        variant="h5"
        onClick={toggleStats}
        sx={{
          textTransform: "uppercase",
          fontSize: "0.9rem",
          textAlign: "right",
          paddingRight: "15px",
          color: "#a9a9a9",
          cursor: "pointer",
        }}
      >
        Show less &#9650;
      </Typography>
    );
  } else {
    button = (
      <Typography
        variant="h5"
        onClick={toggleStats}
        sx={{
          textTransform: "uppercase",
          fontSize: "0.9rem",
          textAlign: "right",
          paddingRight: "15px",
          color: "#a9a9a9",
          cursor: "pointer",
        }}
      >
        Show more &#9660;
      </Typography>
    );
  }

  return (
    <Card
      className="RoleSummary"
      sx={{ marginBottom: { xs: "15px", md: "25px" }, paddingBottom: "20px" }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        sx={{ width: "100%", height: "100%" }}
      >
        <ProgressArc
          isMobile={props.isMobile}
          roleName={props.roleName}
          score={props.roleScore}
          previousScore={props.previousScore}
        />
        <Box sx={{ width: "63%" }}>
          <StyledTable sx={{ borderCollapse: "unset" }}>
            <TableHead>
              <TableRow>
                <Tooltip title={props.tooltip1 || ""} placement="top" arrow>
                  <StyledTableCellHeader>{props.stat1Name}</StyledTableCellHeader>
                </Tooltip>
                <Tooltip title={props.tooltip2 || ""} placement="top" arrow>
                  <StyledTableCellHeader>{props.stat2Name}</StyledTableCellHeader>
                </Tooltip>
                <Tooltip title={props.tooltip3 || ""} placement="top" arrow>
                  <StyledTableCellHeader>{props.stat3Name}</StyledTableCellHeader>
                </Tooltip>
                <Tooltip title={props.tooltip4 || ""} placement="top" arrow>
                  <StyledTableCellHeader>{props.stat4Name}</StyledTableCellHeader>
                </Tooltip>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {isNaN(props.stat1Value) ? (
                  <StyledTableCell>
                    <Skeleton width="50%" sx={{ marginLeft: "auto" }}>
                      <StyledTableCell>.</StyledTableCell>
                    </Skeleton>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell sx={{ color: props.stat1Color}}>{props.stat1Value}</StyledTableCell>
                )}
                {isNaN(props.stat2Value) ? (
                  <StyledTableCell>
                    <Skeleton width="50%" sx={{ marginLeft: "auto" }}>
                      <StyledTableCell>.</StyledTableCell>
                    </Skeleton>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell sx={{ color: props.stat2Color}}>{props.stat2Value}</StyledTableCell>
                )}
                {isNaN(props.stat3Value) ? (
                  <StyledTableCell>
                    <Skeleton width="50%" sx={{ marginLeft: "auto" }}>
                      <StyledTableCell>.</StyledTableCell>
                    </Skeleton>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell sx={{ color: props.stat3Color}}>{props.stat3Value}</StyledTableCell>
                )}
                {isNaN(props.stat4Value) ? (
                  <StyledTableCell>
                    <Skeleton width="50%" sx={{ marginLeft: "auto" }}>
                      <StyledTableCell>.</StyledTableCell>
                    </Skeleton>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell sx={{ color: props.stat4Color}}>{props.stat4Value}</StyledTableCell>
                )}
              </TableRow>
            </TableBody>
          </StyledTable>
          {showHiddenStats ? (
            <StyledTable
              sx={{
                borderCollapse: "unset",
                padding: "25px",
                margin: "0px !important",
              }}
            >
              <TableHead>
                <TableRow>
                <Tooltip title={props.tooltip5 || ""} placement="top" arrow>
                    <StyledTableCellHeader>
                      {props.stat5Name}
                    </StyledTableCellHeader>
                  </Tooltip>
                  <Tooltip title={props.tooltip6 || ""} placement="top" arrow>
                    <StyledTableCellHeader>
                      {props.stat6Name}
                    </StyledTableCellHeader>
                  </Tooltip>
                  <Tooltip title={props.tooltip7 || ""} placement="top" arrow>
                    <StyledTableCellHeader>
                      {props.stat7Name}
                    </StyledTableCellHeader>
                  </Tooltip>
                  <Tooltip title={props.tooltip8 || ""} placement="top" arrow>
                    <StyledTableCellHeader>
                      {props.stat8Name}
                    </StyledTableCellHeader>
                  </Tooltip>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell sx={{ color: props.stat5Color}}>
                    {!isNaN(props.stat5Value) ? props.stat5Value : " "}
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: props.stat6Color}}>
                    {!isNaN(props.stat6Value) ? props.stat6Value : " "}
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: props.stat7Color}}>
                    {!isNaN(props.stat7Value) ? props.stat7Value : " "}
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: props.stat8Color}}>
                    {!isNaN(props.stat8Value) ? props.stat8Value : " "}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </StyledTable>
          ) : (
            ""
          )}
          {hasHiddenStats ? button : ""}
        </Box>
      </Box>
    </Card>
  );
}

export default RoleSummary;
