import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Link,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import Tab from "./Tab";

function seasonFormat(year) {
  const newYear = year.substring(0, 4) + "-" + year.substring(7);
  return newYear;
}
export default function PlayerPageTabBar(props) {
  const navigate = useNavigate();
  const handleClick = (playerid, season, history, status, sr_url) => {
    props.updateSelectedPlayer(playerid, season, history, status, sr_url);
  };

  let tabCount = Object.keys(props.history).length;
  const tabs = [];
  for (let i = tabCount - 1; i >= 0; i--) {
    let year = Object.keys(props.history)[i];
    tabs.push(
      <Tab
        clickHandler={() =>
          handleClick(props.history[year][0], year, props.history, props.status, props.srUrl)
        }
        key={i}
        text={seasonFormat(year)}
        selected={year === props.season}
        sx={{
          cursor: "pointer",
        }}
      />
    );
  }

  const menuItems = [];
  for (let i = tabCount - 1; i >= 0; i--) {
    let year = Object.keys(props.history)[i];
    menuItems.push(
      <MenuItem
        onClick={() =>
          handleClick(props.history[year][0], year, props.history, props.status)
        }
        key={i}
        value={seasonFormat(year)}
      >
        {seasonFormat(year)}
      </MenuItem>
    );
  }

  const allPlayersClick = () => {
    props.updateSelectedPlayer(null, null, null);
    navigate("/");
  };

  return (
    <div>
      <Box
        sx={{
          height: props.isMobile ? "42px" : "62px",
          background: "#D9D9D9",
          position: "relative",
          zIndex: "0",
          borderBottom: "2px solid #787878",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <Container
          maxWidth="1284px"
          sx={{
            paddingLeft: props.isMobile ? "" : "48px !important",
            paddingRight: props.isMobile ? "" : "48px !important",
            display: "flex",
            alignItems: "end",
            maxWidth: "1284px",
          }}
        >
          <Link
            underline="hover"
            sx={{
              color: "black",
              fontWeight: "bold",
              cursor: "pointer",
              //overflow: "hidden",
              //textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              //width: props.isMobile ? "150px" : "fit",
              mr: 2,
              mb: 1,
              ml: 0.5,
            }}
            href={`/`}
            onClick={(event) => {
              if (!event.ctrlKey && !event.metaKey) {
                event.preventDefault();
                allPlayersClick();
              }
            }}
          >
            <span style={{ fontSize: "1.3em", lineHeight: "1em" }}>
              &#8249;
            </span>{" "}
            All Players
          </Link>
          {props.isMobile ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  mb: 1,
                }}
              >
                <FormControl variant="standard">
                  <Select
                    label="Season Select"
                    value={seasonFormat(props.season)}
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "0px",
                        margin: "0px",
                      },
                    }}
                  >
                    {menuItems}
                  </Select>
                </FormControl>
              </Box>
            </>
          ) : (
            <>{tabs}</>
          )}
        </Container>
      </Box>
    </div>
  );
}
