import React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Menu,
  ListSubheader,
  Divider,
  Box,
} from "@mui/material";
import { Close } from "@mui/icons-material";

export default function AboutDialog(props) {
  const people = [
    "Vanna Bushong",
    "Joshua Fink",
    "Kirk Goldsberry",
    "Sana Kohli",
    "Amer Muhanna",
    "Michelle Nguyen",
    "Raina Parikh",
    "Cameron Stringer",
    "Sam Queralt",
  ];

  const version = "2025.0";
  if (props.isMobile) {
    return (
      <div>
        <Menu
          id="about-menu"
          anchorEl={props.anchorEl}
          open={Boolean(props.anchorEl)}
          onClose={props.handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          marginThreshold={0}
          slotProps={{
            paper: {
              sx: {
                width: "100%",
                minWidth: "100%",
                top: "67px !important",
                left: "0px",
                bottom: "0px !important",
                position: "fixed",
                height: "100% !important",
                minHeight: "100%",
                margin: 0,
                //   overflowY: "scroll",
              },
            },
          }}
        >
          <ListSubheader
            divider={true}
            disabled={true}
            sx={{
              alignContent: "center",
              textAlign: "center",
              justifyContent: "center",
              color: "black",
              opacity: "1 !important",
              typography: "normal",
              minWidth: "100%",
              padding: "0px",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            About TransferAI
            <Divider />
          </ListSubheader>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "15px",
              paddingTop: "10px",
            }}
          >
            <Typography variant="largeBold" sx={{ fontWeight: "600" }}>
              Version
            </Typography>
            <Typography variant="large" style={{ marginBottom: "10px" }}>
              {version}
            </Typography>
            <Typography variant="largeBold" sx={{ fontWeight: "600" }}>
              Credits
            </Typography>
            <Typography variant="large">
              {people.map((person, index) => (
                <div key={index}>
                  {person}
                  <br />
                </div>
              ))}
            </Typography>
          </div>
          <Box
            sx={{
              position: "fixed",
              bottom: "0",
              left: "0",
              alignContent: "center",
              textAlign: "center",
              justifyContent: "center",
              paddingTop: "12px",
              paddingBottom: "12px",
              width: "100%",
              background: "white",
              borderTop: 1,
              borderColor: "rgba(0, 0, 0, 0.20)",
            }}
          >
            <Button
              sx={{
                background: "white",
                mr: "12px",
                color: "var(--dark-gray)",
                borderColor: "rgba(0, 0, 0, 0.20)",
              }}
              onClick={() => props.handleClose()}
              variant="outlined"
              size="small"
              typography="h5"
            >
              Close
            </Button>
          </Box>
        </Menu>
      </div>
    );
  } else {
    return (
      <div>
        <Dialog
          onClose={props.handleClose}
          open={Boolean(props.anchorEl)}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "400px",
              },
            },
          }}
        >
          <DialogTitle
            variant="h6"
            id="about-dialog-title"
            sx={{ fontWeight: "600" }}
          >
            About TransferAI
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: "var(--dark-gray-outline)",
            })}
          >
            <Close />
          </IconButton>
          <DialogContent dividers>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="normalBold" sx={{ fontWeight: "600" }}>
                Version
              </Typography>
              <Typography variant="normal" style={{ marginBottom: "10px" }}>
                {version}
              </Typography>
              <Typography variant="normalBold" sx={{ fontWeight: "600" }}>
                Credits
              </Typography>
              <Typography variant="normal">
                {people.map((person, index) => (
                  <div key={index}>
                    {person}
                    <br />
                  </div>
                ))}
              </Typography>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
