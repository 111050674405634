import * as React from "react";

import Button from "@mui/material/Button";

import { useRef } from "react";

import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "13px !important",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem",
  },
}));

function ModalProgressBar(props) {
  const [progress, setProgress] = React.useState(props.playerScore || 0);
  const [progress2, setProgress2] = React.useState(props.playerScore2 || 0);

  const disableColor = "#d9d9d9";
  const worstColor = "rgb(254, 86, 52)";
  const badColor = "#ffb92c";
  const avgColor = "#ffe400";
  const goodColor = "#a7e38f";
  const bestColor = "rgb(47, 198, 138)";

  // Use a ref to control the isFirstRender flag
  const isFirstRenderRef = useRef(true);

  // get color for the first player's score
  const getCurrentColor = () => {
    if (props.playerScore == null) {
      return disableColor;
    }
    if (props.playerScore <= 20) {
      return worstColor;
    } else if (props.playerScore <= 40) {
      return badColor;
    } else if (props.playerScore <= 60) {
      return avgColor;
    } else if (props.playerScore <= 80) {
      return goodColor;
    } else {
      return bestColor;
    }
  };

  // get color for the second player's score
  const getCurrentColor2 = () => {
    if (props.playerScore2 == null) {
      return props.isMobile ? "#5B5B5B" : disableColor;
    }
    if (props.playerScore2 <= 20) {
      return worstColor;
    } else if (props.playerScore2 <= 40) {
      return badColor;
    } else if (props.playerScore2 <= 60) {
      return avgColor;
    } else if (props.playerScore2 <= 80) {
      return goodColor;
    } else {
      return bestColor;
    }
  };

  const svgWidth = 900;
  const svgHeight = 30;
  const barWidth = 900;
  const barHeight = 30;
  const barHeightMobile = 20;
  const barStartX = svgWidth - barWidth;

  const barWidth2 = 900;
  const barStartX2 = barWidth + 20;

  let finalValue = parseFloat(props.playerScore);
  if (isNaN(finalValue)) {
    finalValue = 0;
  }

  let barDivergeWidth = Math.abs(finalValue);
  let barChangeFromPrevious = 0;

  React.useLayoutEffect(() => {
    if (!isFirstRenderRef.current) {
      const startingValue = parseFloat(0);
      barChangeFromPrevious = Math.abs(startingValue);

      let currentStep = 0;
      let currentValue = startingValue;
      const stepDuration = 1 / 300;
      const totalSteps = 350;
      const stepSize = Math.abs(finalValue - currentValue) / totalSteps;

      const interval = setInterval(
        () =>
          setProgress((progress) => {
            currentStep++;
            if (currentStep >= totalSteps) {
              clearInterval(interval);
              return finalValue;
            }
            const stepPct = currentStep * stepSize;
            let pct = currentValue;
            if (finalValue >= startingValue) {
              pct = currentValue + stepPct;
            } else {
              pct = currentValue - stepPct;
            }
            return parseInt(Math.round(pct * 100) / 100);
          }),
        stepDuration * 1000
      );
      return () => clearInterval(interval);
    } else {
      isFirstRenderRef.current = false;
    }
  }, [isFirstRenderRef, finalValue]);

  let finalValue2 = parseFloat(props.playerScore2);
  if (isNaN(finalValue2)) {
    finalValue2 = 0;
  }

  let barDivergeWidth2 = Math.abs(finalValue2);

  React.useLayoutEffect(() => {
    const startingValue = parseFloat(0);
    barChangeFromPrevious = Math.abs(startingValue);

    let currentStep = 0;
    let currentValue = startingValue;
    const stepDuration = 1 / 350;
    const totalSteps = 350;
    const stepSize = Math.abs(finalValue2 - currentValue) / totalSteps;

    const interval = setInterval(
      () =>
        setProgress2((progress2) => {
          currentStep++;
          if (currentStep >= totalSteps) {
            clearInterval(interval);
            return finalValue2;
          }
          const stepPct = currentStep * stepSize;
          let pct = currentValue;
          if (finalValue2 >= startingValue) {
            pct = currentValue + stepPct;
          } else {
            pct = currentValue - stepPct;
          }
          return parseInt(Math.round(pct * 100) / 100);
        }),
      stepDuration * 1000
    );
    return () => clearInterval(interval);
  }, [finalValue2]);

  React.useLayoutEffect(() => {
    if (props.playerScore2 == null) {
    }
  }, []);

  return (
    <>
      {!props.isMobile ? (
        <Box sx={{ display: "flex", flexDirection: "row", marginTop: "15px" }}>
          <StyledText
            variant="body1"
            sx={{
              margin: "0",
              fontWeight: "500",
              display: "inline-flex",
              alignItems: "center",
              width: "12%",
              justifyContent: "right",
              // color: getCurrentColor(),
              color: "#5B5B5B",
            }}
          >
            {props.playerScore == null ? 0 : props.playerScore}%
          </StyledText>
          <Box
            sx={{
              width: { sm: "45%", md: "61%" },
              position: "relative",
              alignItems: "center",
              display: "flex",
              marginTop: "10px",
              height: "14px",
              transform: "scaleX(-1)",
            }}
          >
            <svg
              width="100%"
              height="100%"
              preserveAspectRatio="none"
              viewBox={`10 0 ${svgWidth} ${svgHeight}`}
            >
              <defs>
                <linearGradient
                  xmlns="http://www.w3.org/2000/svg"
                  id="gradient1"
                  x1={0}
                  y1={0}
                  x2={barWidth}
                  y2={barHeight}
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="10%" stopColor={worstColor} />
                  <stop offset="30%" stopColor={badColor} />
                  <stop offset="50%" stopColor={avgColor} />
                  <stop offset="70%" stopColor={goodColor} />
                  <stop offset="90%" stopColor={bestColor} />
                </linearGradient>
              </defs>
              <rect
                x={barStartX - 5}
                y={0}
                width={barWidth}
                height={barHeight}
                rx="20"
                fill={"#d9d9d9"}
              />
              <rect
                x={0}
                y={0}
                width={barDivergeWidth !== 0 ?
                  (barWidth * (progress / barDivergeWidth) * barDivergeWidth) /
                  100
                  : 0
                }
                height={barHeight}
                rx="20"
                fill={"url(#gradient1)"}
              />
            </svg>
          </Box>

          <Button
            sx={{
              pointerEvents: "none",
              alignItems: "center",
              alignSelf: "end",
              background: "white",
              mt: 3,
              width: "29%",
              height: "35px",
              margin: "0px",
              padding: "10px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              display: "inline-block",
              lineHeight: 1,
            }}
            variant="outlined"
            size="large"
          >
            {props.roleName}
          </Button>

          <Box
            sx={{
              width: { sm: "45%", md: "61%" },
              display: "inline-flex",
              position: "relative",
              marginTop: "10px",
              height: "14px",
              alignItems: "center",
              transform: "scaleX(1)",
            }}
          >
            <svg
              width="100%"
              height="100%"
              preserveAspectRatio="none"
              viewBox={`10 0 ${barWidth} ${svgHeight}`}
            >
              <defs>
                <linearGradient
                  xmlns="http://www.w3.org/2000/svg"
                  id="gradient2"
                  x1={barStartX2}
                  y1="0"
                  x2={barWidth}
                  y2="0"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="10%" stopColor={worstColor} />
                  <stop offset="30%" stopColor={badColor} />
                  <stop offset="50%" stopColor={avgColor} />
                  <stop offset="70%" stopColor={goodColor} />
                  <stop offset="90%" stopColor={bestColor} />
                </linearGradient>
              </defs>
              <rect
                x={barStartX - 5}
                y={0}
                width={barWidth}
                height={barHeight}
                rx="20"
                fill={"#d9d9d9"}
              />

              <rect
                x={barStartX}
                y={0}
                width={(barWidth * (progress2 / barWidth) * barWidth) / 100}
                height={barHeight}
                rx="20"
                fill={"url(#gradient1)"}
              />
            </svg>
          </Box>
          <StyledText
            variant="body1"
            sx={{
              margin: "0",
              fontWeight: "500",
              display: "inline-flex",
              alignItems: "center",
              width: "12%",
              justifyContent: "left",
              //color: getCurrentColor2(),
              color: "#5B5B5B",
            }}
          >
            {props.playerScore2 == null ? 0 : props.playerScore2}%
          </StyledText>
        </Box>
      ) : (
        // MOBILE BARS
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginTop: "-2px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "95%",
            }}
          >
            <StyledText
              sx={{
                margin: "0",
                marginTop: "-2px",
                fontWeight: "500",
                display: "inline-flex",
                alignItems: "center",
                width: "17%",
                justifyContent: "right",
                //color: getCurrentColor(),
                color: "#5B5B5B",
                paddingRight: "2px",
              }}
            >
              {props.playerScore == null ? 0 : props.playerScore}%
            </StyledText>
            <Box
              sx={{
                width: "45%",
                position: "relative",
                alignItems: "center",
                display: "flex",
                marginTop: "13px",
                height: "12px",
                transform: "scaleX(-1)",
              }}
            >
              <svg
                width="100%"
                height="100%"
                preserveAspectRatio="none"
                viewBox={`10 0 ${svgWidth} ${svgHeight}`}
              >
                <defs>
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    id="gradient1"
                    x1={0}
                    y1={0}
                    x2={barWidth}
                    y2={barHeightMobile}
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="10%" stopColor={worstColor} />
                    <stop offset="30%" stopColor={badColor} />
                    <stop offset="50%" stopColor={avgColor} />
                    <stop offset="70%" stopColor={goodColor} />
                    <stop offset="90%" stopColor={bestColor} />
                  </linearGradient>
                </defs>
                <rect
                  x={barStartX - 5}
                  y={0}
                  width={barWidth}
                  height={barHeightMobile}
                  rx="30"
                  fill={"#d9d9d9"}
                />
                <rect
                  x={0}
                  y={0}
                  width={barDivergeWidth !== 0 ?
                    (barWidth * (progress / barDivergeWidth) * barDivergeWidth) / 100
                    : 0
                  }
                  height={barHeightMobile}
                  rx="30"
                  fill={"url(#gradient1)"}
                />
              </svg>
            </Box>

            <Button
              id="roleName text"
              sx={{
                pointerEvents: "none",
                alignItems: "center",
                alignSelf: "end",
                background: "white",
                width: "190px",
                height: "35px",
                margin: "0px",
                padding: "0px",
                fontSize: "12px",
              }}
            >
              {props.roleName}
            </Button>

            <Box
              sx={{
                width: "45%",
                display: "inline-flex",
                position: "relative",
                marginTop: "13px",
                height: "12px",
                alignItems: "center",
                transform: "scaleX(1)",
              }}
            >
              <svg
                width="100%"
                height="100%"
                preserveAspectRatio="none"
                viewBox={`10 0 ${barWidth} ${svgHeight}`}
              >
                <defs>
                  <linearGradient
                    xmlns="http://www.w3.org/2000/svg"
                    id="gradient2"
                    x1={barStartX2}
                    y1="0"
                    x2={barWidth}
                    y2="0"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="10%" stopColor={worstColor} />
                    <stop offset="30%" stopColor={badColor} />
                    <stop offset="50%" stopColor={avgColor} />
                    <stop offset="70%" stopColor={goodColor} />
                    <stop offset="90%" stopColor={bestColor} />
                  </linearGradient>
                </defs>
                <rect
                  x={barStartX - 5}
                  y={0}
                  width={barWidth}
                  height={barHeightMobile}
                  rx="30"
                  fill={"#d9d9d9"}
                />

                <rect
                  x={barStartX}
                  y={0}
                  width={(barWidth * (progress2 / barWidth) * barWidth) / 100}
                  height={barHeightMobile}
                  rx="30"
                  fill={"url(#gradient1)"}
                />
              </svg>
            </Box>
            <StyledText
              sx={{
                margin: "0",
                marginTop: "-2px",
                fontWeight: "500",
                display: "inline-flex",
                alignItems: "center",
                width: "17%",
                justifyContent: "left",
                //color: getCurrentColor2(),
                color: "#5B5B5B",
                paddingLeft: "2px",
              }}
            >
              {props.playerScore2 == null ? 0 : props.playerScore2}%
            </StyledText>
          </Box>
        </Box>
      )}
    </>
  );
}

export default ModalProgressBar;
