import * as React from "react";
import { Box, Card } from "@mui/material";
import ProgressBar from "./ProgressBar";
import BlockHeader from "./BlockHeader";

function PlayerSummary(props) {
  return (
    <Box>
      <BlockHeader text="Role Projections"></BlockHeader>
      <Card
        sx={{
          height: "200px",
          paddingTop: "5px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <ProgressBar
          roleName="Impact Score"
          score={props.impactScore}
          previousScore={props.previousImpactScore}
        />
        <ProgressBar
          roleName="Scorer"
          score={props.scorerScore}
          previousScore={props.previousScorerScore}
        />
        <ProgressBar
          roleName="Shooter"
          score={props.shooterScore}
          previousScore={props.previousShooterScore}
        />
        <ProgressBar
          roleName="Finisher"
          score={props.finisherScore}
          previousScore={props.previousFinisherScore}
        />
        <ProgressBar
          roleName="Playmaker"
          score={props.playmakerScore}
          previousScore={props.previousPlaymakerScore}
        />
        <ProgressBar
          roleName="Rebounder"
          score={props.rebounderScore}
          previousScore={props.previousRebounderScore}
        />
        <ProgressBar
          roleName="Defender"
          score={props.defenderScore}
          previousScore={props.previousDefenderScore}
        />
        <ProgressBar
          roleName="Rim Protector"
          score={props.rimprotectorScore}
          previousScore={props.previousRimprotectorScore}
        />
      </Card>
    </Box>
  );
}

export default PlayerSummary;
